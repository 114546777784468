import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButtonPS = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  color: var(--secondary-text);
  width: 128px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: #fff;
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [totalSupply, settotalSupply] = useState(0);
  const [maxMintAmount, setmaxMintAmount] = useState(0);
  const [addressMintedBalance, setaddressMintedBalance] = useState(0);
  const [mintAmount, setMintAmount] = useState(1);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [publicSaleStart, setPublicSaleStart] = useState("");
  const [PreSaleStart, setPreSaleStart] = useState("");
  const [paused, setPaused] = useState("");
  const [whitelistProof, setwhitelistProof] = useState("");
  
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const checkPaused = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .paused()
      .call()
      .then((receipt) => {
        setPaused(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkPublicSaleStart = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .publicSaleStart()
      .call()
      .then((receipt) => {
        setPublicSaleStart(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkPreSaleStart = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .preSaleStart()
      .call()
      .then((receipt) => {
        setPreSaleStart(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checktotalSupply = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply()
      .call()
      .then((receipt) => {
        settotalSupply(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkmaxMintAmount = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .maxMintAmount()
      .call()
      .then((receipt) => {
        setmaxMintAmount(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkaddressMintedBalance = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .addressMintedBalance(blockchain.account)
      .call()
      .then((receipt) => {
        setaddressMintedBalance(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };


  const getWl = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account;
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");
      let whitelist = require("./data/whitelist.json");
      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      //確認したらコメントアウトconsole.log('WL:hasheBefore/'+address);
      //確認したらコメントアウトconsole.log('WL:hashed/'+hashedAddress);
      //確認したらコメントアウトconsole.log("WL:proof/" + proof);//
      //確認したらコメントアウトconsole.log("WL:root/" + root);//
      setwhitelistProof(proof);
      dispatch(fetchData(blockchain.account));
    }
  };


  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`ミント中です。しばらくお待ちください...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("失敗したので再度試してみてください！");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `ミントが成功しました！OpenSeaで確認してみてください！`
        );
        setClaimingNft(false);
        checkaddressMintedBalance();
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTsAL = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    //let totalGasLimit = String(gasLimit * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`ミント中です。しばらくお待ちください...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .preMint(mintAmount, whitelistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("失敗したので再度試してみてください！");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `ミントが成功しました！OpenSeaで確認してみてください！`
        );
        setClaimingNft(false);
        checkaddressMintedBalance();
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > (maxMintAmount - addressMintedBalance)) {
      newMintAmount = maxMintAmount - addressMintedBalance;
    }
    //0より小さい場合は０にする
    if(newMintAmount < 0){
      newMintAmount = 0;
    }
    setMintAmount(newMintAmount);
  };



  useEffect(() => {
    getData();
    getConfig();
    checktotalSupply();
    checkmaxMintAmount();
    checkaddressMintedBalance();
    checkPaused();
    getWl();
    checkPublicSaleStart();
    checkPreSaleStart();
  }, []);

  useEffect(() => {
    getData();
    getConfig();
    checktotalSupply();
    checkmaxMintAmount();
    checkaddressMintedBalance();
    checkPaused();
    getWl();
    checkPublicSaleStart();
    checkPreSaleStart();
  }, [blockchain.account]);

  return (
    <s.Screen>

      <s.TextDescription style={{ position: 'fixed', width: '100%', fontSize: 26, textAlign: "left", color: "#fff", backgroundColor: "rgba(0, 0, 0)"}}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <StyledLink style={{ color: "#fff", margin: "6px 0px 0px 24px" , fontFamily: "'Righteous', cursive"}} href="https://sound-desert.com/">
            SoundDesert
          </StyledLink>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: "24px" }}>
            <a href="https://twitter.com/opera_ayame" target="_blank" rel="noopener" style={{ marginRight: "12px" }}>
              <img style={{ width: "26px",marginTop:"15px" }} src="/config/images/tw.png" alt="Twitter Icon"></img>
            </a>
            <a href="https://opensea.io/ja/collection/butoutochounosymphonia-ryureinachounofragment" target="_blank" rel="noopener">
              <img style={{ width: "26px",marginTop:"15px" }} src="/config/images/os.png" alt="OpenSea Icon"></img>
            </a>
          </div>
        </div>
      </s.TextDescription>

      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 10, backgroundColor: "#000" }}
      >

        <ResponsiveWrapper flex={1} style={{ padding: 2 }} test>

          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              padding: 5,
              borderRadius: 24,
              border: "none",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.5)",
            }}
          >
            <s.Container ai={"center"} jc={"center"} fd={"row"}>
              <img style={{maxWidth: "360px"}} src="/config/images/jacket.png"></img>
            </s.Container>
            {Number(totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)",fontWeight: "bold" }}
                >
                  完売しました！ありがとうございます!!
                </s.TextDescription>
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  →{CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)"}}
                >
                  ※ジャケット画像は一点ずつ異なるので上記の画像はイメージです
                </s.TextDescription>
                <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 40,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >{CONFIG.DISPLAY_COST}ETH+gas
                </s.TextTitle>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontSize: 15}}
                >
                  同時に2mint以上していただけますと両方の楽曲を確実にミント可能です
                </s.TextTitle>

                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.SpacerSmall />
                    <StyledButtonPS
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButtonPS>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>

                      {/* Pubセールここから */}
                      <s.SpacerSmall />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <s.TextDescription
                              style={{ textAlign: "center", color: "var(--accent-text)" }}
                              >- Public mint -
                          </s.TextDescription>
                          </s.Container>
                      {publicSaleStart == true ? (
                        <>
                        {addressMintedBalance > maxMintAmount - 1 ? (
                          <>
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>

                          <StyledButtonPS
                            disabled={1}//claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {"Max Minted" }
                          </StyledButtonPS>
                          </s.Container>

                          </>
                        ) : (
                          <>
                            <s.Container>
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledRoundButton
                                style={{ lineHeight: 0.4 }}
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementMintAmount();
                                }}
                              >
                                -
                              </StyledRoundButton>
                              <s.SpacerMedium />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {mintAmount}
                              </s.TextDescription>
                              <s.SpacerMedium />
                              <StyledRoundButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementMintAmount();
                                }}
                              >
                                +
                              </StyledRoundButton>
                              </s.Container>
                              </s.Container>
                            <s.SpacerSmall />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledButtonPS
                                disabled={claimingNft ? 1 : 0}//claimingNftがtrueなら disabledを表示させる。＝クリックできない
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFTs();
                                  getData();
                                }}
                              >
                              {claimingNft ? "Mint now" : "Mint"}
                              </StyledButtonPS>
                            </s.Container>
                            {addressMintedBalance > 0 ? (
                                <>
                                </>                              
                            ):(
                            <></>
                            )}
                          </>
                        )}
                        </>
                      ) : (
                        <>
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <s.TextTitle
                          style={{
                            color: "var(--accent-text)",
                          }}
                        >
                        {"Coming Soon..."}
                        </s.TextTitle>
                        </s.Container>
                        </>
                      )}
                      {/* Pabここまで */}
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
        </ResponsiveWrapper>
      </s.Container>
    </s.Screen>
  );
}
export default App;